<template>
  <div class="block-wrapper">
    <a-form @submit.stop.prevent="handleSubmit">
      <a-form-item label="Статус">
        <a-checkbox v-model="filter.is_active">
          Активный
        </a-checkbox>
      </a-form-item>

      <a-form-item label="Ключ">
        <a-input v-model="filter.name" required />
      </a-form-item>

      <a-form-item label="Имя">
        <a-input v-model="filter.slug" required />
      </a-form-item>

      <a-form-item label="Тип">
        <a-input v-model="filter.type" required />
      </a-form-item>

      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Сохранить
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { showNotification } from '@/utils'
import { GET_FILTER, UPDATE_FILTER } from '@/store/types'

export default {
  data() {
    return {
      filter: {
        name: '',
        slug: '',
        type: '',
        is_active: false,
      },
    }
  },

  created() {
    this.getFilter()
  },

  methods: {
    handleSubmit() {
      this.$store.dispatch(UPDATE_FILTER, this.filter)
    },

    async getFilter() {
      try {
        this.filter = await this.$store.dispatch(
          GET_FILTER,
          this.$route.params.id,
        )
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
